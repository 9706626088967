export const menuItemsAdmin = {
  items: [
    {
      id: "admin",
      title: "",
      type: "group",
      icon: "icon-support",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/",
          classes: "nav-item ",
          icon: "feather icon-home",
        },
        {
          id: "user-management",
          title: "User Management",
          type: "collapse",
          url: "/user-management",
          classes: "nav-item",
          icon: "feather icon-server",
          children: [
            {
              id: "all",
              title: "All Users",
              type: "item",
              url: "/user-management/all",
            },
            {
              id: "freeze",
              title: "Flagged Users",
              type: "item",
              url: "/user-management/flagged",
            },
            {
              id: "banned",
              title: "Banned Users",
              type: "item",
              url: "/user-management/banned",
            },
            {
              id: "edits",
              title: "Edits",
              type: "item",
              url: "/user-management/edits",
            },
          ]
        },
        {
          id: "customers-rank",
          title: "Customers Rank",
          type: "item",
          url: "/customers-rank",
          classes: "nav-item",
          icon: "feather icon-user",
        },
        {
          id: "access",
          title: "Access Management",
          type: "item",
          url: "/access-management",
          classes: "nav-item",
          icon: "feather icon-lock",
        },
        {
          id: "category",
          title: "Category Management",
          type: "item",
          url: "/category-management",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        {
          id: "rates",
          title: "Rates Management",
          type: "item",
          url: "/rates-management",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "giftcodes",
          title: "Giftcodes",
          type: "collapse",
          url: "/giftcodes",
          classes: "nav-item",
          icon: "feather icon-book",
          children: [
            {
              id: "all",
              title: "All",
              type: "item",
              url: "/giftcodes",
            },
            {
              id: "loyalty",
              title: "Loyalty Reward",
              type: "item",
              url: "/giftcodes/loyalty-reward",
            },
            {
              id: "purchased",
              title: "Purchased",
              type: "item",
              url: "/giftcodes/purchased",
            },
            {
              id: "referralRewards",
              title: "Referral Rewards",
              type: "item",
              url: "/giftcodes/referral-reward",
            },
            {
              id: "manual",
              title: "Manually Generated",
              type: "item",
              url: "/giftcodes/manually-generated",
            },
          ]
        },
        {
          id: "topUp",
          title: "Top Up",
          type: "collapse",
          url: "/topup",
          classes: "nav-item",
          icon: "feather icon-book",
          children: [
            {
              id: "all-topup",
              title: "Top up",
              type: "item",
              url: "/topup",
            },
            {
              id: "flagged-vbas",
              title: "Flagged Topup",
              type: "item",
              url: "/topup/flagged",
            },
          ]
        },
        {
          id: "cryptoWallet",
          title: "Crypto Transactions",
          type: "collapse",
          url: "/crypto-wallet",
          classes: "nav-item",
          icon: "feather icon-book",
          children: [
            {
              id: "all",
              title: "All",
              url: "/crypto-wallet/all",
              type: "item",
            },
            {
              id: "pending",
              title: "Pending",
              url: "/crypto-wallet/pending",
              type: "item",
            },
            {
              id: "completed",
              title: "Completed",
              url: "/crypto-wallet/completed",
              type: "item",
            },
            {
              id: "deleted",
              title: "Deleted",
              url: "/crypto-wallet/deleted",
              type: "item",
            },
          ],
        },

        {
          id: "refill",
          title: "Refill",
          type: "item",
          url: "/refill",
          classes: "nav-item",
          icon: "feather icon-book",
        },

        {
          id: "qcp",
          title: " QCP Management",
          type: "collapse",
          url: "/qcp-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "all",
              title: "All Transactions",
              type: "item",
              url: "/qcp-management/all",
            },
            {
              id: "completed",
              title: "Approved",
              url: "/qcp-management/approved",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/qcp-management/rejected",
              type: "item",
            },
            {
              id: "flagged",
              title: "Flagged",
              type: "item",
              url: "/qcp-management/flagged",
            },
            {
              id: "cleared-approved",
              title: "Cleared Approved",
              url: "/qcp-management/cleared-approved",
              type: "item",
            },

            {
              id: "cleared-rejected",
              title: "Cleared Rejected",
              type: "item",
              url: "/qcp-management/cleared-rejected",
            },
          ],
        },

        {
          id: "buyTransaction",
          title: "Buy Transaction Management",
          type: "item",
          url: "/buy-transactions",
          classes: "nav-item",
          icon: "feather icon-file-plus",
        },

        {
          id: "transaction",
          title: "Sell Transaction Management",
          type: "collapse",
          url: "/transaction-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "all",
              title: "All Orders",
              type: "item",
              url: "/transaction-management/all",
            },
            {
              id: "completed",
              title: "Completed",
              url: "/transaction-management/completed",
              type: "item",
            },
            {
              id: "pending",
              title: "Pending",
              url: "/transaction-management/pending",
              type: "item",
            },
            {
              id: "archived",
              title: "Archived",
              url: "/transaction-management/archived",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/transaction-management/rejected",
              type: "item",
            },

            {
              id: "rejection-reason",
              title: "Rejection Reason",
              type: "item",
              url: "/transaction-management/rejection-reason",
            },
          ],
        },

        {
          id: "ncpTransaction",
          title: "NCP Transactions",
          type: "collapse",
          url: "/ncp-transaction-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "completed",
              title: "Completed",
              url: "/ncp-transaction-management/completed",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/ncp-transaction-management/rejected",
              type: "item",
            },
          ],
        },

        {
          id: "payment",
          title: "Payment Management",
          type: "collapse",
          url: "/payment-management",
          classes: "nav-item",
          icon: "feather icon-clipboard",
          children: [
            {
              id: "pending",
              title: "Pending",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/pending",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  type: "item",
                  url: "/payment-management/cedis/pending",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  type: "item",
                  url: "/payment-management/dollars/pending",
                },
              ],
            },
            {
              id: "rejected",
              title: "Rejected",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/rejected",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  type: "item",
                  url: "/payment-management/cedis/rejected",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  type: "item",
                  url: "/payment-management/dollars/rejected",
                },
              ],
            },
            {
              id: "approved",
              title: "Approved",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/approved",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  type: "item",
                  url: "/payment-management/cedis/approved",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  type: "item",
                  url: "/payment-management/dollars/approved",
                },
              ],
            },
            {
              id: "others",
              title: "Others ",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/others",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  url: "/payment-management/cedis/others",
                  type: "item",
                },
              ],
            },
            {
              id: "processing",
              title: "Processing",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/processing",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  url: "/payment-management/cedis/processing",
                  type: "item",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  url: "/payment-management/dollars/processing",
                  type: "item",
                },
              ],
            },
          ],
        },
        {
          id: "balance",
          title: "Wallet Balance",
          type: "item",
          url: "/wallet-balance",
          classes: "nav-item",
          icon: "feather icon-book",
        },

        {
          id: "cnwithdrawal",
          title: "CN Withdrawals",
          type: "collapse",
          url: "/cn-withdrawals",
          classes: "nav-item",
          icon: "feather icon-clipboard",
          children: [
            {
              id: "all",
              title: "All CN Withdrawals",
              type: "item",
              url: "/cn-withdrawals/all",
            },
            {
              id: "pending",
              title: "Pending",
              url: "/cn-withdrawals/pending",
              type: "item",
            },
            {
              id: "completed",
              title: "Approved",
              url: "/cn-withdrawals/approved",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/cn-withdrawals/rejected",
              type: "item",
            },
            {
              id: "processing",
              title: "Processing",
              url: "/cn-withdrawals/processing",
              type: "item",
            },
            {
              id: "failed",
              title: "Failed",
              url: "/cn-withdrawals/failed",
              type: "item",
            },
          ],
        },

        {
          id: "referrals",
          title: "Referrals",
          type: "item",
          url: "/referrals",
          classes: "nav-item",
          icon: "feather icon-user",
        },
        {
          id: "contact",
          title: "Contact Users",
          type: "item",
          url: "/contact-users",
          classes: "nav-item",
          icon: "feather icon-file-text",
        },
        {
          id: "cryptoTransactionSettings",
          title: "Crypto Send-out Transaction",
          type: "collapse",
          url: "/crypto-transaction",
          classes: "nav-item",
          icon: "feather icon-book",
          children: [
            {
              id: "sendOutTransaction",
              title: "Send-out Transaction",
              url: "/crypto-transaction",
              type: "item",
            },
            {
              id: "history",
              title: "History",
              url: "/crypto-transaction/history",
              type: "item",
            },
            {
              id: "balance",
              title: "Balance",
              url: "/crypto-transaction/balance",
              type: "item",
            },
          ],
        },
        {
          id: "settings",
          title: "Settings",
          type: "item",
          url: "/settings",
          classes: "nav-item",
          icon: "feather icon-sliders",
        },
        {
          id: "logout",
          title: "Logout",
          type: "item",
          url: "/logout",
          classes: "nav-item",
          icon: "feather icon-user",
        },
      ],
    },
  ],
};

export const subAdminRoutes = {
  items: [
    {
      id: "admin",
      title: "",
      type: "group",
      icon: "icon-support",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/",
          classes: "nav-item ",
          icon: "feather icon-home",
        },

        {
          id: "user-management",
          title: "User Management",
          type: "collapse",
          url: "/user-management",
          classes: "nav-item",
          icon: "feather icon-server",
          children: [
            {
              id: "all",
              title: "All Users",
              type: "item",
              url: "/user-management/all",
            },
            {
              id: "freeze",
              title: "Flagged Users",
              type: "item",
              url: "/user-management/flagged",
            },
            {
              id: "banned",
              title: "Banned Users",
              type: "item",
              url: "/user-management/banned",
            },
            {
              id: "edits",
              title: "Edits",
              type: "item",
              url: "/user-management/edits",
            },
          ]
        },
        {
          id: "category",
          title: "Category Management",
          type: "item",
          url: "/category-management",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        {
          id: "rates",
          title: "Rates Management",
          type: "item",
          url: "/rates-management",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "topUp",
          title: "Top Up",
          type: "collapse",
          url: "/topup",
          classes: "nav-item",
          icon: "feather icon-book",
          children: [
            {
              id: "all-topup",
              title: "Top up",
              type: "item",
              url: "/topup",
            },
            {
              id: "flagged-vbas",
              title: "Flagged Topup",
              type: "item",
              url: "/topup/flagged",
            },
          ]
        },
        {
          id: "cryptoWallet",
          title: "Crypto Transactions",
          type: "collapse",
          url: "/crypto-wallet",
          classes: "nav-item",
          icon: "feather icon-book",
          children: [
            {
              id: "pending",
              title: "Pending",
              url: "/crypto-wallet/pending",
              type: "item",
            },
            {
              id: "completed",
              title: "Completed",
              url: "/crypto-wallet/completed",
              type: "item",
            },
            {
              id: "deleted",
              title: "Deleted",
              url: "/crypto-wallet/deleted",
              type: "item",
            },
          ],
        },


        {
          id: "refill",
          title: "Refill",
          type: "item",
          url: "/refill",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "qcp",
          title: " QCP Management",
          type: "collapse",
          url: "/qcp-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "all",
              title: "All Transactions",
              type: "item",
              url: "/qcp-management/all",
            },
            {
              id: "completed",
              title: "Approved",
              url: "/qcp-management/approved",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/qcp-management/rejected",
              type: "item",
            },
            {
              id: "flagged",
              title: "Flagged",
              type: "item",
              url: "/qcp-management/flagged",
            },
            {
              id: "cleared-approved",
              title: "Cleared Approved",
              url: "/qcp-management/cleared-approved",
              type: "item",
            },

            {
              id: "cleared-rejected",
              title: "Cleared Rejected",
              type: "item",
              url: "/qcp-management/cleared-rejected",
            },
          ],
        },
        {
          id: "buyTransaction",
          title: "Buy Transaction Management",
          type: "item",
          url: "/buy-transactions",
          classes: "nav-item",
          icon: "feather icon-file-plus",
        },
        {
          id: "transaction",
          title: "Sell Transaction Management",
          type: "collapse",
          url: "/transaction-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "all",
              title: "All Orders",
              type: "item",
              url: "/transaction-management/all",
            },
            {
              id: "completed",
              title: "Completed",
              url: "/transaction-management/completed",
              type: "item",
            },
            {
              id: "pending",
              title: "Pending",
              url: "/transaction-management/pending",
              type: "item",
            },
            {
              id: "archived",
              title: "Archived",
              url: "/transaction-management/archived",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/transaction-management/rejected",
              type: "item",
            },

            {
              id: "rejection-reason",
              title: "Rejection Reason",
              type: "item",
              url: "/transaction-management/rejection-reason",
            },
          ],
        },
        {
          id: "ncpTransaction",
          title: "NCP Transactions",
          type: "collapse",
          url: "/ncp-transaction-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "completed",
              title: "Completed",
              url: "/ncp-transaction-management/completed",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/ncp-transaction-management/rejected",
              type: "item",
            },
          ],
        },

        {
          id: "payment",
          title: "Payment Management",
          type: "collapse",
          url: "/payment-management",
          classes: "nav-item",
          icon: "feather icon-clipboard",
          children: [
            {
              id: "pending",
              title: "Pending",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/pending",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  type: "item",
                  url: "/payment-management/cedis/pending",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  type: "item",
                  url: "/payment-management/dollars/pending",
                },
              ],
            },
            {
              id: "rejected",
              title: "Rejected",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/rejected",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  type: "item",
                  url: "/payment-management/cedis/rejected",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  type: "item",
                  url: "/payment-management/dollars/rejected",
                },
              ],
            },
            {
              id: "approved",
              title: "Approved",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/approved",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  type: "item",
                  url: "/payment-management/cedis/approved",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  type: "item",
                  url: "/payment-management/dollars/approved",
                },
              ],
            },
            {
              id: "others",
              title: "Others ",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/others",
                  type: "item",
                },
              ],
            },
            {
              id: "processing",
              title: "Processing",
              type: "collapse",
              children: [
                {
                  id: "naira",
                  title: "Naira",
                  url: "/payment-management/naira/processing",
                  type: "item",
                },
                {
                  id: "cedis",
                  title: "Cedis",
                  url: "/payment-management/cedis/processing",
                  type: "item",
                },
                {
                  id: "dollars",
                  title: "Dollars",
                  url: "/payment-management/dollars/processing",
                  type: "item",
                },
              ],
            },
          ],
        },
        {
          id: "balance",
          title: "Wallet Balance",
          type: "item",
          url: "/wallet-balance",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "referrals",
          title: "Referrals",
          type: "item",
          url: "/referrals",
          classes: "nav-item",
          icon: "feather icon-user",
        },
        {
          id: "quickSettings",
          title: "Quick Settings",
          type: "item",
          url: "/quick-settings",
          classes: "nav-item",
          icon: "feather icon-sliders",
        },
        {
          id: "logout",
          title: "Logout",
          type: "item",
          url: "/logout",
          classes: "nav-item",
          icon: "feather icon-user",
        },
      ],
    },
  ],
};