import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";
import AuthGuard from "./components/Auth/AuthGuard";
import {
	selectCurrentUser,
	accessToken,
} from "./store/selectors/auth.selector";
import { useSelector } from "react-redux";
import { BASE_URL } from "./libs";

const PermissionWrapper = ({ children, permissonLevel, ignore }) => {
	const user = useSelector(selectCurrentUser);
	const levels = {
		admin: 3,
		subAdmin: 2,
		users: 1,
	};
	return !user?.role ? (
		<Redirect to="/login" />
	) : user?.role && user?.role !== "user" ? (
		children
	) : user?.role && levels[user?.role] <= permissonLevel ? (
		<Redirect to="/404" />
	) : (
		<Redirect to="/login" />
	);
};

export const renderRoutes = (routes = []) => (
	<Suspense fallback={<Loader />}>
		<Switch>
			{routes.map((route, i) => {
				const Guard = route.guard || Fragment;
				const Layout = route.layout || Fragment;
				const Component = route.component;

				return (
					<Route
						key={i}
						path={route.path}
						exact={route.exact}
						render={(props) =>
							route.ignore ? (
								<Guard>
									<Layout>
										{route.routes ? (
											renderRoutes(route.routes)
										) : (
											<Component {...props} />
										)}
									</Layout>
								</Guard>
							) : (
								<PermissionWrapper
									key={i}
									permissonLevel={route?.permissonLevel ?? 1}
								>
									<Guard>
										<Layout>
											{route.routes ? (
												renderRoutes(route.routes)
											) : (
												<Component {...props} />
											)}
										</Layout>
									</Guard>
								</PermissionWrapper>
							)
						}
					/>
				);
			})}
		</Switch>
	</Suspense>
);

const routes = [
	{
		exact: true,
		path: "/login",
		component: lazy(() => import("./views/Login")),
		ignore: true,
	},
	{
		exact: true,
		path: "/404",
		component: lazy(() => import("./views/errors/NotFound404")),
		ignore: true,
	},

	{
		path: "*",
		layout: AdminLayout,
		guard: AuthGuard,
		routes: [
			{
				exact: true,
				path: "/",
				component: lazy(() => import("./views/Dashboard")),
			},
			{
				exact: true,
				path: "/rates-management",
				component: lazy(() => import("./views/Rates-management")),
			},
			{
				exact: true,
				path: "/settings",
				component: lazy(() => import("./views/Settings")),
				permissonLevel: 3,
			},
			{
				exact: true,
				path: "/quick-settings",
				component: lazy(() => import("./views/QuickSettings")),
			},
			{
				exact: true,
				path: "/logout",
				component: lazy(() => import("./views/Logout")),
			},
			{
				exact: true,
				path: "/contact-users",
				component: lazy(() => import("./views/Contact-user")),
				permissonLevel: 3,
			},

			{
				exact: true,
				path: "/rates-management",
				component: lazy(() => import("./views/Rates-management")),
			},

			{
				exact: true,
				path: "/access-management",
				component: lazy(() => import("./views/Access-management")),
				permissonLevel: 3,
			},
			{
				exact: true,
				path: "/category-management",
				component: lazy(() => import("./views/Category-management")),
			},
			//user related pages
			{
				exact: true,
				path: "/user-management/all",
				component: lazy(() => import("./views/User-management")),
			},
			{
				exact: true,
				path: "/user-management/flagged",
				component: lazy(() =>
					import("./views/User-management/flagged")
				),
			},
			{
				exact: true,
				path: "/user-management/banned",
				component: lazy(() =>
					import("./views/User-management/banned")
				),
			},
			{
				exact: true,
				path: "/user-management/edits",
				component: lazy(() =>
					import("./views/User-management/edits")
				),
			},

			{
				exact: true,
				path: "/customers-rank",
				component: lazy(() => import("./views/CustomersRank")),
			},

			{
				exact: true,
				path: "/user-management/:userId",
				component: lazy(() =>
					import("./views/User-management/user-page")
				),
			},

			//naira payment
			{
				exact: true,
				path: "/payment-management/naira/pending",
				component: lazy(() =>
					import("./views/PaymentManagment/Naira/pending")
				),
			},
			{
				exact: true,
				path: "/payment-management/naira/approved",
				component: lazy(() =>
					import("./views/PaymentManagment/Naira/approved")
				),
			},
			{
				exact: true,
				path: "/payment-management/naira/others",
				component: lazy(() =>
					import("./views/PaymentManagment/Naira/others")
				),
			},
			{
				exact: true,
				path: "/payment-management/naira/rejected",
				component: lazy(() =>
					import("./views/PaymentManagment/Naira/rejected")
				),
			},
			{
				exact: true,
				path: "/payment-management/naira/processing",
				component: lazy(() =>
					import("./views/PaymentManagment/Naira/processing")
				),
			},

			//cedis payment

			{
				exact: true,
				path: "/payment-management/cedis/pending",
				component: lazy(() =>
					import("./views/PaymentManagment/Cedis/pending")
				),
			},
			{
				exact: true,
				path: "/payment-management/cedis/approved",
				component: lazy(() =>
					import("./views/PaymentManagment/Cedis/approved")
				),
			},
			{
				exact: true,
				path: "/payment-management/cedis/rejected",
				component: lazy(() =>
					import("./views/PaymentManagment/Cedis/rejected")
				),
			},
			{
				exact: true,
				path: "/payment-management/cedis/others",
				component: lazy(() =>
					import("./views/PaymentManagment/Cedis/others")
				),
			},
			{
				exact: true,
				path: "/payment-management/cedis/processing",
				component: lazy(() =>
					import("./views/PaymentManagment/Cedis/processing")
				),
			},

			//dollars payment
			{
				exact: true,
				path: "/payment-management/dollars/pending",
				component: lazy(() =>
					import("./views/PaymentManagment/Dollars/pending")
				),
			},
			{
				exact: true,
				path: "/payment-management/dollars/approved",
				component: lazy(() =>
					import("./views/PaymentManagment/Dollars/approved")
				),
			},
			{
				exact: true,
				path: "/payment-management/dollars/rejected",
				component: lazy(() =>
					import("./views/PaymentManagment/Dollars/rejected")
				),
			},
			{
				exact: true,
				path: "/payment-management/dollars/processing",
				component: lazy(() =>
					import("./views/PaymentManagment/Dollars/processing")
				),
			},

			// Rejection Reason
			{
				exact: true,
				path: "/transaction-management/rejection-reason",
				component: lazy(() =>
					import("./views/Transaction-management/rejectionReason")
				),
			},

			//naira transactions
			{
				exact: true,
				path: "/transaction-management/all",
				component: lazy(() =>
					import("./views/Transaction-management/allorders")
				),
			},
			{
				exact: true,
				path: "/transaction-management/pending",
				component: lazy(() =>
					import("./views/Transaction-management/pending")
				),
			},
			{
				exact: true,
				path: "/transaction-management/archived",
				component: lazy(() =>
					import("./views/Transaction-management/archived")
				),
			},
			{
				exact: true,
				path: "/transaction-management/completed",
				component: lazy(() =>
					import("./views/Transaction-management/completed")
				),
			},
			{
				exact: true,
				path: "/transaction-management/rejected",
				component: lazy(() =>
					import("./views/Transaction-management/rejected")
				),
			},

			// ---------NCP TRANSACTIONS --------------------------------
			{
				exact: true,
				path: "/ncp-transaction-management/completed",
				component: lazy(() => import("./views/NCP-Transactions/completed")),
			},
			{
				exact: true,
				path: "/ncp-transaction-management/rejected",
				component: lazy(() =>
					import("./views/NCP-Transactions/rejected")
				),
			},

			//QCP management
			{
				exact: true,
				path: "/qcp-management/all",
				component: lazy(() =>
					import("./views/QCP-management/allorders")
				),
			},
			{
				exact: true,
				path: "/qcp-management/approved",
				component: lazy(() =>
					import("./views/QCP-management/approved")
				),
			},
			{
				exact: true,
				path: "/qcp-management/rejected",
				component: lazy(() =>
					import("./views/QCP-management/rejected")
				),
			},
			{
				exact: true,
				path: "/qcp-management/flagged",
				component: lazy(() =>
					import("./views/QCP-management/flagged")
				),
			},
			{
				exact: true,
				path: "/qcp-management/cleared-approved",
				component: lazy(() =>
					import("./views/QCP-management/cleared-approved")
				),
			},
			{
				exact: true,
				path: "/qcp-management/cleared-rejected",
				component: lazy(() =>
					import("./views/QCP-management/cleared-rejected")
				),
			},

			//----------CN Withdrawals--------------//
			{
				exact: true,
				path: "/cn-withdrawals/pending",
				component: lazy(() =>
					import("./views/CNWithdrawals/pending")
				),
			},
			{
				exact: true,
				path: "/cn-withdrawals/approved",
				component: lazy(() =>
					import("./views/CNWithdrawals/approved")
				),
			},
			{
				exact: true,
				path: "/cn-withdrawals/rejected",
				component: lazy(() =>
					import("./views/CNWithdrawals/rejected")
				),
			},
			{
				exact: true,
				path: "/cn-withdrawals/all",
				component: lazy(() =>
					import("./views/CNWithdrawals/allorders")
				),
			},
			{
				exact: true,
				path: "/cn-withdrawals/processing",
				component: lazy(() =>
					import("./views/CNWithdrawals/processing")
				),
			},
			{
				exact: true,
				path: "/cn-withdrawals/failed",
				component: lazy(() =>
					import("./views/CNWithdrawals/failed")
				),
			},

			{
				exact: true,
				path: "/buy-transactions",
				component: lazy(() => import("./views/GiftcardPurchases")),
				permissonLevel: 3,
			},


			{
				exact: true,
				path: "/giftcodes",
				component: lazy(() => import("./views/Giftcodes")),
				permissonLevel: 3,
			},
			{
				exact: true,
				path: "/giftcodes/loyalty-reward",
				component: lazy(() => import("./views/Giftcodes/LoyaltyRewards")),
				permissonLevel: 3,
			},
			{
				exact: true,
				path: "/giftcodes/referral-reward",
				component: lazy(() => import("./views/Giftcodes/ReferralRewards")),
				permissonLevel: 3,
			},
			{
				exact: true,
				path: "/giftcodes/purchased",
				component: lazy(() => import("./views/Giftcodes/Purchased")),
				permissonLevel: 3,
			},
			{
				exact: true,
				path: "/giftcodes/manually-generated",
				component: lazy(() => import("./views/Giftcodes/Manual")),
				permissonLevel: 3,
			},

			{
				exact: true,
				path: "/topup",
				component: lazy(() => import("./views/Topup")),
			},
			{
				exact: true,
				path: "/topup/flagged",
				component: lazy(() => import("./views/Topup/Flagged")),
			},
			{
				exact: true,
				path: "/referrals",
				component: lazy(() => import("./views/Referrals")),
			},

			// ------------WALLET BALANCE---------------
			{
				exact: true,
				path: "/wallet-balance",
				component: lazy(() => import("./views/WalletBalance")),
			},

			// ------------CRYPTO WALLET SETTINGS---------------
			{
				exact: true,
				path: "/crypto-transaction",
				component: lazy(() =>
					import("./views/CryptoTransactionSettings/index")
				),
			},
			{
				exact: true,
				path: "/crypto-transaction/history",
				component: lazy(() =>
					import("./views/CryptoTransactionSettings/history")
				),
			},
			{
				exact: true,
				path: "/crypto-transaction/balance",
				component: lazy(() =>
					import("./views/CryptoTransactionSettings/balance")
				),
			},


			// ------------CRYPTO WALLET---------------
			{
				exact: true,
				path: "/crypto-wallet/pending",
				component: lazy(() =>
					import("./views/CryptoWallet/pending")
				),
			},
			{
				exact: true,
				path: "/crypto-wallet/all",
				component: lazy(() =>
					import("./views/CryptoWallet/all")
				),
			},
			{
				exact: true,
				path: "/crypto-wallet/deleted",
				component: lazy(() =>
					import("./views/CryptoWallet/deleted")
				),
			},
			{
				exact: true,
				path: "/crypto-wallet/completed",
				component: lazy(() =>
					import("./views/CryptoWallet/completed")
				),
			},

			// ------------CRYPTO WALLET SETTINGS---------------
			{
				exact: true,
				path: "/crypto-transaction",
				component: lazy(() =>
					import("./views/CryptoTransactionSettings/index")
				),
			},
			{
				exact: true,
				path: "/crypto-transaction/history",
				component: lazy(() =>
					import("./views/CryptoTransactionSettings/history")
				),
			},
			{
				exact: true,
				path: "/crypto-transaction/balance",
				component: lazy(() =>
					import("./views/CryptoTransactionSettings/balance")
				),
			},

			{
				exact: true,
				path: "/refill",
				component: lazy(() => import("./views/Refill")),
			},
			{
				path: "*",
				exact: true,
				component: () => <Redirect to={BASE_URL} />,
			},
		],
	},
];

export default routes;
